<template>
  <div class="text-center">
    <img alt="Vue logo" src="../assets/logo.png" class="mx-auto">
    <Lens :count="left" variant="left" @update="update"/>
    <Lens :count="right" variant="right" @update="update"/>
    <div class="mt-10">
      <div class="inline-block w-1/2 p-2">
        <button @click="use" class="w-1/2 rounded-md bg-blue-500 text-white p-2">Use</button>
      </div>
      <div class="inline-block w-1/2 p-2">
        <button @click="reset" class="w-1/2 rounded-md bg-red-500 text-white p-2">Reset</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import Lens from '@/components/Lens.vue'

export default defineComponent({
  name: 'Home',
  components: {
    Lens
  },
  setup () {
    const store = useStore()
    return {
      left: computed(() => store.state.left),
      right: computed(() => store.state.right),
      reset: () => store.dispatch('reset'),
      use: () => store.dispatch('use'),
      update: (payload: { count: number, variant: string }) => store.dispatch('update', payload)
    }
  }
})
</script>
