
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import Lens from '@/components/Lens.vue'

export default defineComponent({
  name: 'Home',
  components: {
    Lens
  },
  setup () {
    const store = useStore()
    return {
      left: computed(() => store.state.left),
      right: computed(() => store.state.right),
      reset: () => store.dispatch('reset'),
      use: () => store.dispatch('use'),
      update: (payload: { count: number, variant: string }) => store.dispatch('update', payload)
    }
  }
})
