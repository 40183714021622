import { createStore } from 'vuex'
import { save, get, defaults } from '@/service/storage'

const data = get()

export default createStore({
  state: {
    left: data.left,
    right: data.right
  },
  mutations: {
    reset (state) {
      state.left = defaults.left
      state.right = defaults.right
    },
    use (state) {
      if (state.left > 0) {
        state.left--
      }
      if (state.right > 0) {
        state.right--
      }
    },
    update (state, payload) {
      if (payload.variant === 'left') {
        state.left = payload.count
      } else {
        state.right = payload.count
      }
    }
  },
  actions: {
    reset ({ commit, state }) {
      commit('reset')
      save(state)
    },
    use ({ commit, state }) {
      commit('use')
      save(state)
    },
    update ({ commit, state }, payload) {
      commit('update', payload)
      save(state)
    }
  },
  modules: {
  }
})
