import { Data } from '@/models/data'

const key = 'lens'
export const defaults = {
  right: 30,
  left: 30
}

export function save (data: Data): void {
  localStorage.setItem(key, JSON.stringify({
    left: data.left,
    right: data.right
  }))
}

export function get (): Data {
  if (checkIfStorageIsSupported()) {
    const data = localStorage.getItem(key)
    if (data !== null) {
      return JSON.parse(data)
    }
  }

  return defaults
}

function checkIfStorageIsSupported () {
  const test = 'test'
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}
