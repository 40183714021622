<template>
  <div class="fixed inset-x-0 z-10">
    <div class="bg-blue-100 px-4 py-2 border-b-2 border-blue-500 z-10">
      <button class="fixed left-4 bg-transparent" @click="toggleNavbar">
        <MenuIcon class="h-5 w-5 text-blue-500"/>
      </button>
      <span class="block text-center text-blue-500 font-bold">Lens</span>
    </div>
    <div id="nav" class="fixed inset-y-0 left-0 px-4 py-2 min-w-1/2 pt-11 bg-gradient-to-b from-blue-400 to-purple-500 shadow -z-1 transform transition-transform" :class="[navbarCollapsed ? '-translate-x-full shadow-none' : '']">
      <router-link to="/" class="text-white block px-4 py-2 -mx-4" @click="toggleNavbar"><HomeIcon class="h-5 w-5 inline-block"/> <span class="align-middle">Home</span></router-link>
      <router-link to="/configuration" class="text-white block px-4 py-2 -mx-4 border-t-2 border-blue-300" @click="toggleNavbar"><CogIcon class="h-5 w-5 inline-block"/> <span class="align-middle">Configuration</span></router-link>
      <router-link to="/api/use" class="text-white block px-4 py-2 -mx-4 border-t-2 border-blue-300" @click="toggleNavbar"><CogIcon class="h-5 w-5 inline-block"/> <span class="align-middle">Use</span></router-link>
    </div>
  </div>
  <div class="container mx-auto p-4 pt-11">
    <router-view/>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { MenuIcon, HomeIcon, CogIcon } from '@heroicons/vue/solid'

export default defineComponent({
  name: 'App',
  components: {
    MenuIcon,
    HomeIcon,
    CogIcon
  },
  setup () {
    const navbarCollapsed = ref(true)
    function toggleNavbar () {
      navbarCollapsed.value = !navbarCollapsed.value
    }

    return {
      navbarCollapsed,
      toggleNavbar
    }
  }
})
</script>
