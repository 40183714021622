
<template>
  <div class="inline-block w-1/2 p-2">
    <button class="block w-full p-4 py-8 bg-blue-500 font-bold text-white rounded-md" @click="toggleDialog">
      {{ count }}
    </button>
  </div>
  <div class="fixed inset-0 bg-gray-500 bg-opacity-95 z-10" :class="[isOpen ? 'block' : 'hidden']">
    <div class="p-4 bg-white rounded m-4">
      <input type="number" min="0" step="1" v-model.number="day" class="block text-center">
      <button class="w-1/2 rounded-md bg-blue-500 text-white p-2 mt-2" @click="update">Mettre à jour</button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'Lens',
  props: {
    count: Number,
    variant: String
  },
  setup (props, { emit }) {
    const isOpen = ref(false)
    const day = ref(props.count)

    function toggleDialog () {
      isOpen.value = !isOpen.value
    }

    function update () {
      toggleDialog()
      emit('update', {
        count: day.value,
        variant: props.variant
      })
    }

    return {
      isOpen,
      day,
      toggleDialog,
      update
    }
  }
})
</script>
