
import { defineComponent, ref } from 'vue'
import { MenuIcon, HomeIcon, CogIcon } from '@heroicons/vue/solid'

export default defineComponent({
  name: 'App',
  components: {
    MenuIcon,
    HomeIcon,
    CogIcon
  },
  setup () {
    const navbarCollapsed = ref(true)
    function toggleNavbar () {
      navbarCollapsed.value = !navbarCollapsed.value
    }

    return {
      navbarCollapsed,
      toggleNavbar
    }
  }
})
