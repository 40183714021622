
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'Lens',
  props: {
    count: Number,
    variant: String
  },
  setup (props, { emit }) {
    const isOpen = ref(false)
    const day = ref(props.count)

    function toggleDialog () {
      isOpen.value = !isOpen.value
    }

    function update () {
      toggleDialog()
      emit('update', {
        count: day.value,
        variant: props.variant
      })
    }

    return {
      isOpen,
      day,
      toggleDialog,
      update
    }
  }
})
