
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Use',
  setup () {
    const store = useStore()
    const router = useRouter()

    store.dispatch('use')
    router.push({ name: 'Home' })
  }
})
